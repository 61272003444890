body {
	margin: 0;
	font-family: 'EuclidCircularB-Regular';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	box-sizing: border-box;
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'EuclidCircularB-Regular';
	src: url('assets/fonts/EuclidCircularB-Regular.eot');
	src: url('assets/fonts/EuclidCircularB-Regular.eot?#iefix') format('embedded-opentype'),
		url('assets/fonts/EuclidCircularB-Regular.woff2') format('woff2'),
		url('assets/fonts/EuclidCircularB-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Archia-medium';
	src: url('assets/fonts/archia-medium-webfont.eot');
	src: url('assets/fonts/archia-medium-webfont.eot?#iefix') format('embedded-opentype'),
		url('assets/fonts/archia-medium-webfont.woff2') format('woff2'),
		url('assets/fonts/archia-medium-webfont.ttf') format('truetype');
}
